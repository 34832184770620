import React, { useState, useEffect } from 'react'
// Components
import {
  FilterSortMenu,
  Loading,
  ProductCard,
  Seo,
  Container,
  Divider,
} from '../components'
// Styles
import {
  StickyContainer,
  ProductsGrid,
  CategoryHeader,
  CategoryDescription,
} from '../styles/LandingPage.styles'
// Context
import { useStoreContext } from '../context/StoreContext'
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
import { useCartContext } from '../context/CartContext'
import { sortCategories, configureCategories } from '../utils/productHelpers'

const defaultFilterState = {
  filterBy: '',
  option: '',
  showFiltered: false,
}

const ProductsPage = () => {
  const [productList, setProductList] = useState([])
  const [allProductsSorted, setAllProductsSorted] = useState([])
  const [filterByName, setFilterByName] = useState(null)
  const [filteredDescription, setFilteredDescription] = useState(null)
  const [{ option, filterBy, showFiltered }, setState] =
    useState(defaultFilterState)
  const {
    prismicData: {
      prismicProductsPage: { loading_products, products, ordered_categories },
    },
  } = usePrismic()
  const { storeData, isStoreLoading } = useStoreContext()
  const { isPcOfferInCart, isAmbOfferInCart, isAmbPremiumOfferInCart } =
    useCartContext()
  const { userType } = useAuthContext()

  const categoryMap = configureCategories(ordered_categories)

  const benefitOptions = storeData['benefit']?.subCategories ?? []
  let typeOptions = []
  const tempOptArray = Object.entries(storeData['type']?.subCategories ?? {})
  if (tempOptArray.length !== 0) {
    typeOptions = Object.fromEntries(
      tempOptArray.filter(([key, val]) => {
        if (
          !isPcOfferInCart &&
          !isAmbOfferInCart &&
          !isAmbPremiumOfferInCart &&
          userType === 'RETAIL'
        ) {
          return key !== 'packs'
        }
        if (val.products.length < 1) {
          return false
        }
        return true
      })
    )
  }

  const typesAndBenefits = { ...benefitOptions, ...typeOptions }

  useEffect(() => {
    if (typesAndBenefits) {
      const sortedCategories = sortCategories(
        typesAndBenefits,
        ordered_categories
      )
      setAllProductsSorted(sortedCategories)
    }
  }, [storeData])

  const handleShowProducts = (value, text) => {
    if (value === '') {
      return setState(defaultFilterState)
    }
    setState({
      option: text,
      filterBy: value,
      showFiltered: true,
    })
  }

  useEffect(() => {
    if (!filterBy) return
    if (option === 'By Type') {
      setProductList(typeOptions[filterBy]?.products)
      setFilterByName(typeOptions[filterBy]?.name)
      setFilteredDescription(
        categoryMap[typeOptions[filterBy]?.url_key]?.description
      )
    } else {
      setProductList(benefitOptions[filterBy]?.products)
      setFilterByName(benefitOptions[filterBy]?.name)
      setFilteredDescription(
        categoryMap[benefitOptions[filterBy]?.url_key]?.description
      )
    }
  }, [filterBy])

  if (isStoreLoading)
    return <Loading loading={true} message={loading_products[0].text} />

  return (
    <StickyContainer>
      <Seo title={products[0].text} />
      <div style={{ position: 'sticky', zIndex: 2, top: 0 }}>
        <FilterSortMenu
          types={typeOptions}
          benefits={benefitOptions}
          handleFilter={handleShowProducts}
        />
      </div>
      {filterBy ? (
        <Container direction="column" align="flex-start" margin="0 0 0 1rem">
          <CategoryHeader textAlign="center" size="huge">
            {filterByName}
          </CategoryHeader>
          <CategoryDescription>{filteredDescription || ''}</CategoryDescription>
        </Container>
      ) : null}
      {showFiltered ? (
        <ProductsGrid>
          {productList?.map(product => (
            <ProductCard key={product.uid} {...product} />
          ))}
        </ProductsGrid>
      ) : (
        <>
          {allProductsSorted?.map((category, index) => (
            <div key={category.uid}>
              <Container
                direction="column"
                align="flex-start"
                margin="0 0 0 1rem"
              >
                <CategoryHeader>{category.name || ''}</CategoryHeader>
                <CategoryDescription>
                  {category?.description || ''}
                </CategoryDescription>
              </Container>
              <ProductsGrid>
                {category.products.map(product => (
                  <ProductCard key={product.uid} {...product} />
                ))}
              </ProductsGrid>
              {index !== allProductsSorted.length - 1 && (
                <Container>
                  <Divider />
                </Container>
              )}
            </div>
          ))}
        </>
      )}
    </StickyContainer>
  )
}

export default ProductsPage
